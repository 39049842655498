import { ThemeProvider } from "@emotion/react";
import { Card, createTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { MaterialLayout } from "../../layout/material/MaterialLayout";
import { IAuthLayout } from "../../../../interfaces/globalComponents/authComponents/authLayout/IAuthLayout";

export const AuthLayout = ({ children }: IAuthLayout) => {
  const theme = useSelector((state: RootState) => state.theme);
  return (
    <ThemeProvider theme={createTheme(theme.materialTheme)}>
      <MaterialLayout>
        <Card
          sx={{
            backgroundColor: theme.componentColors.surface.surfaceDim,
            backgroundImage: "none",
          }}
          className="flex flex-col space-y-[10px] px-[16px] py-[40px] 
           lg:flex-row lg:items-center lg:justify-between
           lg:h-full  lg:px-[64px] lg:py-[64px] 3xl:py-[125px] 3xl:px-[120px] rounded-[26px]
          "
        >
          {children}
        </Card>
      </MaterialLayout>
    </ThemeProvider>
  );
};
