import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { AuthLayout } from "../components/global/auth/authLayout/AuthLayout";
import { Stack, Typography } from "@mui/material";
import { Pod1umFullLogo } from "../components/global/logo/Pod1umFullLogo";
import { LoginForm } from "../components/global/auth/login/LoginForm";
import { IGetStaticProps } from "../interfaces/next/IGetStaticProps";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Head from "next/head";

export default function Login() {
  const theme = useSelector((state: RootState) => state.theme);

  return (
    <AuthLayout>
      <Head>
        <title>Login | Pod1um</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Stack className="flex flex-col items-center justify-center px-[16px] pb-[40px] text-[52px] lg:items-start">
        <Pod1umFullLogo />
        <Typography
          sx={{
            color: theme.componentColors.primary.primary,
          }}
          variant="h1"
          className="text-[32px] font-medium hidden lg:inline"
        >
          Experience Elite.
        </Typography>
        <Typography
          sx={{
            color: theme.componentColors.surface.onSurfaceVariant,
          }}
          className="text-center lg:text-left text-[16px] font-light mt-[14px]"
        >
          Get access to coaches, plans & programs proven at the highest level.
        </Typography>
      </Stack>
      <LoginForm />
    </AuthLayout>
  );
}

export async function getStaticProps({
  locale,
}: {
  locale: string;
}): Promise<IGetStaticProps> {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common", "menu", "footer"])),
    },
  };
}
